import * as React from 'react';
import Alert from '@mui/material/Alert';
import {AlertTitle, Snackbar, Typography, useMediaQuery, useTheme} from "@mui/material";
import {FaSwimmingPool} from "react-icons/fa";
import {AiOutlinePhone} from "react-icons/ai";

export default function InfoAlerts() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = React.useState(true);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const callTo = (tel, e) => {
        window.location = "tel:" + tel
        e.preventDefault()
    };

    return (
        <Snackbar open={open} onClose={handleClose} anchorOrigin={{horizontal: "center", vertical: "bottom"}}
                  sx={{width: "90%"}}>
            <Alert onClose={handleClose} severity="warning" variant={"filled"}
                   icon={matches ? false : <FaSwimmingPool size={100}/>}
                   sx={{width: "90%"}}>
                <AlertTitle>
                    <Typography component={'span'} variant={matches ? "subtitle1" : "h4"}>Zadzwoń i zapewnij sobie miejsce na
                        zajęciach!</Typography>
                </AlertTitle>
                <Typography component={'span'} variant={matches ? "subtitle1" : "body1"}>
                    <div onClick={e => callTo("+48 511 624 644", e)}>
                        <AiOutlinePhone style={{marginRight: 10}}/>Iza: 511 624 644
                    </div>
                    <div onClick={e => callTo("+48 663 765 274", e)}>
                        <AiOutlinePhone style={{marginRight: 10}}/>Monika: 663 765 274
                    </div>

                </Typography>

            </Alert>
        </Snackbar>
    );
}
