import React from "react";
import styled from "styled-components";
import CrawlSwimmer from "../../assets/CrawlSwimmer";
import ButterflySwimmer from "../../assets/ButterflySwimmer";
import BreaststrokeSwimmer from "../../assets/BreaststrokeSwimmer";
import BackstrokeSwimmer from "../../assets/BackstrokeSwimmer";


const headers = {
    main: "Najlesza szkoła pływania dla dzieci w Łodzi"
}

const HeadSection = () => {

    const color = "#0B093B"

    return (
        <Wrapper>
            <div className="container">

                <StyledCrawl data-aos="fade-right" data-aos-delay="500">
                    <CrawlSwimmer fill={color}/>
                </StyledCrawl>
                <StyledButterfly data-aos="fade-left" data-aos-delay="500">
                    <ButterflySwimmer fill={color}/>
                </StyledButterfly>
                <StyledBackstroke data-aos="fade-up" data-aos-delay="500">
                    <BackstrokeSwimmer fill={color}/>
                </StyledBackstroke>
                <StyledBreaststroke data-aos="fade-up" data-aos-delay="500">
                    <BreaststrokeSwimmer fill={color}/>
                </StyledBreaststroke>
                <SmallScreenSwimmers>
                    <SmallSwimmer><CrawlSwimmer fill={color}/></SmallSwimmer>
                    <SmallSwimmer><ButterflySwimmer fill={color}/></SmallSwimmer>
                    <SmallSwimmer><BackstrokeSwimmer fill={color}/></SmallSwimmer>
                    <SmallSwimmer><BreaststrokeSwimmer fill={color}/></SmallSwimmer>
                </SmallScreenSwimmers>


                <Header className="textCenter flexCenter">{headers.main}</Header>
            </div>
        </Wrapper>
    );
}

export default HeadSection;

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 650px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
  @media (max-width: 576px) {
    flex-direction: column;
    min-height: 500px;
  }
`;

const Header = styled.h1.attrs({
    className: "regular"
})`
  font-size: 5em;
  min-height: 500px;
  @media (max-width: 960px) {
    font-size: 3em;
    min-height: 400px;
  }
  @media (max-width: 576px) {
    font-size: 2em;
    min-height: 100px;
  }
`;

const StyledCrawl = styled.div`
  height: 200px;
  width: 200px;
  position: absolute;
  z-index: 1;
  margin-top: 50px;
  margin-left: -100px;
  @media (max-width: 1360px) {
    margin-left: 0;
    height: 120px;
    width: 120px;
  }
  @media (max-width: 960px) {
    display: none;
  }
`;

const StyledButterfly = styled.div`
  height: 200px;
  width: 200px;
  position: absolute;
  z-index: 1;
  margin-top: 10px;
  margin-left: 1000px;
  @media (max-width: 1360px) {
    margin-left: 1000px;
    height: 120px;
    width: 120px;
  }
  @media (max-width: 1160px) {
    margin-left: 800px;
    height: 120px;
    width: 120px;
  }
  @media (max-width: 960px) {
    display: none;
  }
`;

const StyledBackstroke = styled.div`
  height: 200px;
  width: 200px;
  position: absolute;
  z-index: 1;
  margin-top: 300px;
  margin-left: 900px;
  @media (max-width: 1360px) {
    height: 120px;
    width: 120px;
    margin-left: 900px;
    margin-top: 400px;
  }
  @media (max-width: 1160px) {
    height: 120px;
    width: 120px;
    margin-left: 800px;
  }
  @media (max-width: 960px) {
    display: none;
  }
`;

const StyledBreaststroke = styled.div`
  height: 200px;
  width: 200px;
  position: absolute;
  z-index: 1;
  margin-top: 400px;
  margin-left: 200px;
  @media (max-width: 1360px) {
    height: 120px;
    width: 120px;
    margin-top: 400px;
    margin-left: 200px;
  }
  @media (max-width: 1160px) {
    height: 120px;
    width: 120px;
    margin-top: 350px;
    margin-left: 200px;
  }
  @media (max-width: 960px) {
    display: none;
  }
`;

const SmallScreenSwimmers = styled.div`
  display: none;
  @media (max-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const SmallSwimmer = styled.div`
  margin: 30px;
  height: 100px;
  width: 100px;
  @media (max-width: 576px) {
    height: 50px;
    width: 50px;
  }
`;
