import React from "react";
import ReactGA from 'react-ga4'
import {Helmet} from "react-helmet";
import Main from "./components/Main";

ReactGA.initialize("G-J9G5DW2ZHE");
ReactGA.send("pageview");

const App = () =>
    <>
        <Helmet>
            <meta charSet="utf-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
            <title>Nauka pływania dla dzieci i dorosłych w Łodzi | Retkińska Szkoła Pływania</title>
            <meta name="description"
                  content="Profesjonalna szkoła nauki pływania w Łodzi dla dzieci i dorosłych. Wieloletnie doświadczenie kadry trenerskiej"/>
            <link rel="canonical" href="http://rspszkolaplywania.pl"/>
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
            <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;600;800&display=swap"
                  rel="stylesheet"/>
        </Helmet>
        <Main/>
    </>

export default App;
