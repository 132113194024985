import * as React from "react";

const BackstrokeSwimmer = ({fill}) =>
    <svg version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 1000 1000"
         preserveAspectRatio="none"
         shapeRendering="auto"
    >
        <g>
            <path fill={fill}
                  d="M64.1,6.6C36.8,6.6,14.7,28.7,14.7,56v530.7c0,27.3,22.2,49.4,49.4,49.3c27.2,0,49.3-22.1,49.3-49.3V56  C113.5,28.7,91.4,6.6,64.1,6.6z M341.1,399.8c-103.2,0-186.8,83.6-186.8,186.8s83.6,186.8,186.8,186.8s186.8-83.6,186.8-186.8  S444.3,399.8,341.1,399.8L341.1,399.8z M341.1,692.8c-58.7-0.2-106.1-48-105.8-106.7c0.2-58.7,48-106.1,106.7-105.8  c58.6,0.2,105.9,47.8,105.8,106.4c-0.1,58.7-47.7,106.2-106.4,106.1C341.3,692.8,341.2,692.8,341.1,692.8z M970.7,721  c-19-19.5-50.2-19.9-69.6-0.9c-0.3,0.3-0.6,0.6-0.9,0.9l-152,152.4L595.5,720.7c-19.5-19.5-51.1-19.5-70.6,0  c-19.5,19.5-19.5,51.1,0,70.6l187.7,187.7c2.4,2.4,5,4.5,7.8,6.3c16.5,10.9,37.8,10.9,54.3,0c2.8-1.8,5.4-4,7.8-6.3l187.7-187.7  c19.6-18.8,20.3-50,1.5-69.6c-0.3-0.3-0.6-0.6-0.9-0.9V721z"/>

        </g>
    </svg>

export default BackstrokeSwimmer;