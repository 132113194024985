import React from "react";
import styled from "styled-components"

import IzaPath from "../../assets/iza.jpg"
import MonikaPath from "../../assets/monika.jpg"
import {GiSwimfins} from 'react-icons/gi';
import {Box, Container} from "@mui/material";

const managers = [
    {
        fullName: "Izabela Gutenplan",
        bio: [
            "Absolwentka Akademii Wychowania Fizycznego w Warszawie oraz Szkoły Mistrzostwa Sportowego w Raciborzu.",
            "Wieloletnia zawodniczka, specjalizująca się w stylu dowolnym, medalistka Mistrzostw Polski Juniorów i Seniorów, licencjonowana trenerka II klasy w pływaniu.",
            "Od wielu lat związana ze Szkołą Podstawową nr 137 w Łodzi jako nauczyciel wychowania fizycznego oraz trenerka w klubie SKS 137 Delfin Łódź. Jej młodzi zawodnicy zdobywają medale w zawodach pływackich na szczeblu wojewódzkim i ogólnopolskim.",
            "Lubi dobrą muzykę, taniec i egzotyczne podróże."
        ],
        photo: IzaPath
    },
    {
        fullName: "Monika Rozwadowska",
        bio: [
            "Absolwentka Akademii Wychowania Fizycznego w Poznaniu, instruktor i ratownik WOPR, sędzia pływacki klasy związkowej, nauczyciel pływania w SP 137 oraz trener w klubie pływackim SKS 137 Delfin",
            "Aktualnie trener w klubie Delfin, z sukcesami swoich podopiecznych na zawodach, finalistami Mistrzostw Polski.",
            "Od najmłodszych lat związana z pływaniem. Była zawodniczka klubu Delfin i specjalistka od stylu zmiennego, medalistka Mistrzostw Polski Juniora Młodszego.",
            "Pasjonatka biegów oraz aktywnego wypoczynku."
        ],
        photo: MonikaPath
    }
];

const ManagersSection = () =>
    <Wrapper>
        <Container>
            <Header>Kadra trenerska</Header>

            {managers.map((manager, i) =>

                <div className="row" style={{padding: 10}} key={i}>

                    <ImageWrapper>
                        <StyledImage src={manager.photo} alt="nauka pływania dla dzieci w łodzi"/>
                    </ImageWrapper>

                    <div className="col-sm-12 col-md-7">
                        <NameHeader>{manager.fullName}</NameHeader>
                        <ul>
                            {manager.bio.map((bio, i) =>
                                <Box sx={{margin: "30px 0 30px 0"}} key={i}>
                                    <div className="flexNullCenter">
                                        <div style={{marginRight: "20px"}}><GiSwimfins size="50"/></div>
                                        <p>{bio}</p>
                                    </div>
                                </Box>
                            )}
                        </ul>
                    </div>
                </div>)
            }

        </Container>
    </Wrapper>

export default ManagersSection;

const Wrapper = styled.div`
  color: whitesmoke;
  background-color: #0B093B;
`;

const Header = styled.div.attrs({
    className: "textCenter regular font60"
})`
  padding: 50px;
`;

const NameHeader = styled.h2.attrs({
    className: "textCenter regular font40"
})`
  padding: 20px;
`;

const StyledImage = styled.img.attrs({
    className: "radius6"
})`
  width: 100%;

  @media (max-width: 990px) {
    width: 50%;
  }
`

const ImageWrapper = styled.div.attrs({
    className: "col-sm-12 col-md-5"
})`
  max-height: 600px;

  @media (max-width: 990px) {
    text-align: center;
  }
`