import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Link} from "react-scroll";
import {FaFacebookF} from "react-icons/fa";
import LogoIconPath from "../../assets/logo.png";

const TopNavbar = () => {
    const [y, setY] = useState(window.scrollY);

    useEffect(() => {
        window.addEventListener("scroll", () => setY(window.scrollY));
        return () => {
            window.removeEventListener("scroll", () => setY(window.scrollY));
        };
    }, [y]);

    return (
        <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? {height: "60px"} : {height: "80px"}}>
            <NavInner className="container flexSpaceCenter">
                <Link className="pointer flexNullCenter" to="home" smooth={true}>
                    <img src={LogoIconPath} style={{width: 200, height: 50}} alt="szkola plywania lodz"/>
                </Link>
                <ul className="flexNullCenter">
                    <a href="https://www.facebook.com/RetkinskaSzkolaPlywania" target="_blank" rel="noreferrer">
                        <Fb><FaFacebookF/></Fb>
                    </a>
                </ul>
            </NavInner>
        </Wrapper>);
}

export default TopNavbar;

const Fb = styled.div`
  font-size: 2em;
  padding: 5px;
  border-radius: 0.2rem;
  
  &:hover {
    color: whitesmoke;
    background-color: #0B093B;
    cursor: pointer;
  }
`

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;

const NavInner = styled.div`
  position: relative;
  height: 100%;
`
