import React, {useCallback, useEffect, useState} from "react";
import Cookies from "js-cookie";
import styled from "styled-components";
import {Snackbar} from "@mui/material";

const cookies = {
    description: "Ta strona używa plików cookies i danych analitycznych, aby zapewnić najwyższą jakość usług.",
    buttonText: "Akceptuje"
}

const CookieConsent = () => {

    const [isVisible, setIsVisible] = useState(false);

    const onAccept = useCallback(() => {
        Cookies.set("rps-cookie-accpet", "", {expires: 365});
        setIsVisible(false);
    }, [setIsVisible]);

    useEffect(() => {
        if (Cookies.get("rps-cookie-accpet") === undefined) {
            setIsVisible(true);
        }
    }, []);

    return (
        <Snackbar open={isVisible}>
            <Wrapper className="flexNullCenter">
                <div>{cookies.description}</div>
                <StyledButton onClick={onAccept}>{cookies.buttonText}</StyledButton>
            </Wrapper>
        </Snackbar>
    );
}

export default CookieConsent;

const Wrapper = styled.div`
  background-color: whitesmoke;
  margin: 5px;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid rgba(11, 9, 59, 1.0);
  color: rgba(11, 9, 59, 1.0);
`;

const StyledButton = styled.button`
  display: inline-block;
  border-radius: 3px;
  padding: 0.5rem 0;
  margin: 0.5rem 1rem;
  width: 11rem;
  background: rgba(11, 9, 59, 0.5);
  color: rgba(11, 9, 59, 1.0);
  border: 1px solid rgba(11, 9, 59, 1.0);

  &:hover {
    color: whitesmoke;
    background: rgba(11, 9, 59, 1.0);
    cursor: pointer;
  }
`;
