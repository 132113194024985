import React from "react";
import ScheduleCard from "../ScheduleCard";
import styled from "styled-components";

const schedule = [
    {
        day: "Poniedziałek",
        hours: [
            {from: "18:00", to: "18:45"},
            {from: "18:45", to: "19:30"},
            {from: "19:30", to: "20:15"}
        ]
    },
    {
        day: "Czwartek",
        hours: [
            {from: "18:00", to: "18:45"},
            {from: "18:45", to: "19:30"},
            {from: "19:30", to: "20:15"}
        ]
    },
    {
        day: "Piątek",
        hours: [
            {from: "18:00", to: "18:45"},
            {from: "18:45", to: "19:30"},
            {from: "19:30", to: "20:15"}
        ]
    }
];

const headers = {
    main: "Grafik zajęć"
}

const ScheduleSection = () =>
    <div className="container">
        <Header className="textCenter">{headers.main}</Header>
        <div className="row">
            {schedule.map(element => (
                <div key={element.day} className="col-xs-12 col-md-12 col-lg-4" style={{padding: 10}}>
                    <ScheduleCard day={element.day} hours={element.hours}/>
                </div>
            ))}
        </div>
    </div>

export default ScheduleSection;

const Header = styled.div.attrs({
    className: "textCenter regular font60"
})`
  padding: 50px;
`;
