import * as React from "react";

const ButterflySwimmer = ({fill}) =>
    <svg version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 1000 1000"
         preserveAspectRatio="none"
         shapeRendering="auto"
    >
        <g>
            <path fill={fill}
                  d="M991.5,498L858.1,364.6c-13.8-13.5-35.8-13.5-49.7,0L675,498c-13.8,13.8-13.5,36.2,0.4,50  c13.8,13.8,36.2,13.5,50-0.4l108.6-108.6l108.6,108.6c12.4,14.9,34.4,17,49.3,4.6c14.9-12.4,17-34.4,4.6-49.3  C995,500.9,993.2,499.4,991.5,498z M197.9,364.3c-1.8-1.8-3.5-3.2-5.7-4.3c-11.7-7.8-27-7.8-38.7,0c-2.1,1.1-3.9,2.8-5.7,4.3  L14.5,498c-9.6,9.9-12.8,24.8-7.4,37.6c1.8,4.3,4.3,8.2,7.8,11.7l0,0c13.5,13.8,35.8,13.8,49.3,0l0,0l108.2-108.6l108.6,108.6  c13.8,13.8,36.2,13.8,50,0.4c13.8-13.8,13.8-36.2,0.4-50L197.9,364.3z M503.3,389.5c-73.4,0-132.7,59.6-132.7,132.7  s59.6,132.7,132.7,132.7S636,595.6,636,522.5l0,0C636,449.1,576.4,389.5,503.3,389.5z M503.3,597.7c-41.9,0-75.2-34.1-75.2-75.9  c0-41.9,34.1-75.2,75.9-75.2c41.5,0,75.2,34.1,75.2,75.6c0,41.9-33.3,75.6-75.2,75.9c-0.4,0-0.4,0-0.7,0V597.7z"/>
        </g>
    </svg>

export default ButterflySwimmer;