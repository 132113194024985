import React from "react";
import styled from "styled-components"
import {RiMedalLine} from "react-icons/ri";

const PriceCard = ({title, pricing, features, highlighted}) =>
    <Card>
        <div className="flexNullCenter">
            {highlighted ? <RiMedalLine style={{fontSize: "4em", color: "#F2B300"}}/> : null}
            <p className="textCenter font25">{title}</p>
        </div>
        <PriceP>{pricing}</PriceP>

        {features.map((feature, index) => (
            <div key={index} className="flexNullCenter">
                <div style={{marginLeft: 10}}>
                    <p className="thin">{feature}</p>
                </div>
            </div>
        ))}
    </Card>

export default PriceCard;

const PriceP = styled.p.attrs({
    className: "font30"
})`
  margin: 20px 0 20px 0;
`;

const Card = styled.div.attrs({
    className: "radius6"
})`
  margin: 10px;
  padding: 30px;
  color: #0B093B;
  background-color: whitesmoke;

  &:hover {
    transition: transform .5s;
    transform: scale(1.1);
    cursor: default;
    color: whitesmoke;
    background-color: #0B093B;
    border: whitesmoke solid 1px;
  }
`;
