import React from "react";
import styled from "styled-components";
import {AiOutlineMail, AiOutlinePhone} from "react-icons/ai";
import {FiExternalLink} from "react-icons/fi";
import {MdOutlinePlace} from "react-icons/md";

const infos = [
    {
        name: "Iza",
        mobile: "+48 511 624 644",
        mail: "rsp.izagutenplan@gmail.com"
    },
    {
        name: "Monika",
        mobile: "+48 663 765 274",
        mail: "rsp.monikarozwadowska@gmail.com"
    }
];

const headers = {
    contactHeader: "Zostańmy w kontakcie",
    placeHeader: "Gdzie Nas szukać?"
}

const address = {
    directions: "Łódź, Florecistów 3B",
    place: "Pływalnia SP 137"
}

const Footer = () => {

    const callTo = (tel, e) => {
        window.location = "tel:" + tel
        e.preventDefault()
    };

    const mailTo = (mail, e) => {
        window.location = "mailto:" + mail
        e.preventDefault()
    };

    const openMap = (e) => {
        window.open("https://goo.gl/maps/2xH5osLo7FNuUGRC8", "_blank")
    }

    return (
        <footer className="container">
            <div>
                <Header>{headers.contactHeader}</Header>

                <div className="row">
                    {infos.map((info, i) =>
                        <div className="col-xs-12 col-md-12 col-lg-6" style={{margin: "10px 0px 10px 0px"}} key={i}>
                            <NameHeader>{info.name}</NameHeader>

                            <Contact onClick={e => mailTo(info.mail, e)}>
                                <AiOutlineMail style={{marginRight: 10}}/>
                                {info.mail}
                            </Contact>

                            <Contact onClick={e => callTo(info.mobile, e)}>
                                <AiOutlinePhone style={{marginRight: 10}}/>
                                {info.mobile}
                            </Contact>

                        </div>
                    )}
                </div>

                <div style={{marginBottom: 20}}>
                    <Header>{headers.placeHeader}</Header>
                    <Place onClick={e => openMap(e)}>
                        <MdOutlinePlace size="70" style={{marginRight: 20}}/>
                        <div>
                            <StyleH>{address.place}</StyleH>
                            <StyleH>{address.directions}</StyleH>
                        </div>
                    </Place>
                </div>
            </div>
        </footer>);
}

export default Footer;

const StyleH = styled.h1.attrs({
    className: "regular"
})`
`

const Place = styled.div.attrs({
    className: "font15 flexCenter"
})`
  padding: 10px;
  border: 1px solid #ffffff;

  &:hover {
    border-radius: 0.6rem;
    color: #F5F5F5;
    background-color: #0B093B;
    cursor: pointer;
  }

  @media (max-width: 1200px) {
    text-align: center;
  }
`

const Contact = styled.div.attrs({
    className: "font20"
})`
  padding: 10px;
  border: 1px solid #ffffff;

  &:hover {
    border-radius: 0.6rem;
    color: #F5F5F5;
    background-color: #0B093B;
    cursor: pointer;
  }
`

const NameHeader = styled.p.attrs({
    className: "regular font40"
})`
  @media (max-width: 1200px) {
    text-align: center;
  }
`

const Header = styled.div.attrs({
    className: "textCenter regular font60"
})`
  padding: 50px;
`;
