import * as React from "react";
import styled, {keyframes} from "styled-components";

const SvgComponent = ({upperColor, lowerColor, animationNegativeDelays}) => {
    const id = String(Math.random());

    return (
        <div style={{background: upperColor}}>
            <svg
                style={{
                    position: 'relative',
                    width: "100%",
                    marginBottom: -7,
                    height: '7vw',
                    minHeight: '7vw',
                    marginTop: 200
                }}
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                viewBox="0 24 150 28"
                preserveAspectRatio="none"
                shapeRendering="auto"
            >
                <defs>
                    <path
                        id={id}
                        d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                    />
                </defs>
                <StyledG style={{animationDelay: `-${animationNegativeDelays}`}}>
                    <use href={`#${id}`} x="48" y="0" fill={lowerColor}/>
                </StyledG>
            </svg>
        </div>
    );
}

export default SvgComponent;

const move = keyframes`
    from {
        transform: translate3d(-90px, 0, 0)
    }
    to {
        transform: translate3d(85px, 0, 0)
    } 
`

const StyledG = styled.g`
    animation: ${move} 4s cubic-bezier(0.62, 0.5, 0.38, 0.5) infinite;
`;
