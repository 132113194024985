import React from "react";
import styled from "styled-components"
import PriceCard from "./PriceCard";

const headers = {
    main: "Cennik"
}

const enterPass = [
    {
        title: "Jednorazowe wejście",
        pricing: <><b>55 zł</b></>,
        features: []
    },
    {
        title: "Pakiet miesięczny: Dzieci",
        pricing: <><b>37 zł</b> / wejście</>,
        features: []
    },
    {
        title: "Pakiet miesięczny: Dorośli",
        pricing: <><b>45 zł</b> / wejście</>,
        features: []
    },
    {
        title: "Zajęcia indywidualne",
        pricing: <><b>120 zł</b></>,
        features: []
    }
]

const PricingSection = () =>
    <Wrapper>
        <div className="container">
            <Header>{headers.main}</Header>
            <div className="row">
                {enterPass.map((pass, i) =>
                    <div className="col-xs-12 col-lg-3" key={i}>
                        <PriceCard
                            highlighted={pass.highlighted}
                            title={pass.title}
                            pricing={pass.pricing}
                            features={pass.features}
                        />
                    </div>
                )}
            </div>
        </div>
    </Wrapper>

export default PricingSection;


const Wrapper = styled.div`
  color: whitesmoke;
  background-color: #0B093B;
`;

const Header = styled.div.attrs({
    className: "textCenter regular font60"
})`
  padding: 50px;
`;
