import * as React from "react";

const BreaststrokeSwimmer = ({fill}) =>
    <svg version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 1000 1000"
         preserveAspectRatio="none"
         shapeRendering="auto"
    >
        <g>
            <path fill={fill}
                  d="M300.1,424.4c-20.6-21.1-54.4-21.6-75.5-1c-0.3,0.3-0.7,0.7-1,1L20.1,627.9c-2.6,2.6-4.9,5.4-6.9,8.4  c-13.9,21.3-11,49.3,6.9,67.3l203.5,203.5c2.5,2.6,5.3,4.8,8.4,6.7c24.7,16.3,57.9,9.6,74.2-15.1c14.1-21.3,11.2-49.5-6.9-67.5  L134.5,665.7l165.6-165.6c20.9-20.8,21-54.6,0.2-75.5C300.2,424.5,300.2,424.4,300.1,424.4z M986.2,636.3c-2-3-4.3-5.9-6.9-8.4  L776,424.4c-7.5-7.7-17.2-13.1-27.7-15.3c-3.4-0.6-6.8-0.9-10.3-0.9c-29.6-0.3-53.8,23.5-54,53.1c-0.1,14.6,5.7,28.5,16.1,38.7  l165.7,165.6L700.1,831.2c-21.1,21.1-21.1,55.4,0,76.5c21.1,21.1,55.4,21.1,76.5,0l203.4-203.5C998.1,686,1000.7,657.5,986.2,636.3z   M500.1,76.4c-111.9,0-202.6,90.7-202.6,202.6s90.7,202.6,202.6,202.6S702.7,390.9,702.7,279S612,76.4,500.1,76.4L500.1,76.4z   M500.1,394.1c-63.6-0.3-115-52-114.8-115.7c0.3-63.6,52-115,115.7-114.8c63.5,0.3,114.8,51.9,114.8,115.4  c-0.1,63.6-51.7,115.1-115.4,115.1C500.3,394.1,500.2,394.1,500.1,394.1z"/>
        </g>
    </svg>

export default BreaststrokeSwimmer;