import React from "react";
import AOS from "aos/dist/aos";
import TopNavbar from "./navigation/TopNavbar";
import "aos/dist/aos.css";
import Wave from "./Wave";
import CookieConsent from "./CookieConsent";
import HeadSection from "./sections/HeadSection";
import ManagersSection from "./sections/ManagersSection";
import ScheduleSection from "./sections/SheduleSection";
import PricingSection from "./PricingSection";
import Footer from "./sections/Footer";
import InfoAlert from "./sections/InfoStack";

// https://michalsnik.github.io/aos/
AOS.init({once: true});

const Main = () =>
    <>
        <TopNavbar/>
        <CookieConsent/>
        <InfoAlert/>

        <HeadSection/>
        <Wave lowerColor="#0B093B" animationNegativeDelays={3}/>

        <ManagersSection/>
        <Wave upperColor="#0B093B" lowerColor="white" animationNegativeDelays={6}/>

        <ScheduleSection/>
        <Wave upperColor="white" lowerColor="#0B093B" animationNegativeDelays={40}/>

        <PricingSection/>
        <Wave upperColor="#0B093B" lowerColor="white" animationNegativeDelays={2}/>

        <Footer/>
        <Wave upperColor="white" lowerColor="#0B093B" animationNegativeDelays={2}/>
    </>

export default Main;
