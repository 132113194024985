import * as React from "react";

const CrawlSwimmer = ({fill}) =>
    <svg version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 1000 1000"
         preserveAspectRatio="none"
         shapeRendering="auto"
    >
        <g>
            <path fill={fill} d="M413.9,242.4c-94.7,0-171.4,76.7-171.4,171.4s76.7,171.4,171.4,171.4s171.4-76.7,171.4-171.4l0,0
		C585.3,319.1,508.6,242.4,413.9,242.4z M413.9,511.1c-53.8-0.2-97.3-44-97.1-97.9c0.2-53.8,44-97.3,97.9-97.1
		c53.7,0.2,97.2,43.9,97.1,97.6c-0.1,53.8-43.8,97.4-97.6,97.4C414.1,511.1,414,511.1,413.9,511.1z"/>
            <path fill={fill} d="M982.4,225.4c-1.8-8.9-6.4-17.1-12.9-23.4L797.2,29.8c-6.3-6.6-14.4-11.1-23.3-12.9
		c-14.9-2.7-30.2,2.2-40.8,12.9L561.6,202.5c-17.7,17.9-17.6,46.7,0.3,64.4c17.9,17.7,46.7,17.6,64.4-0.3l140.1-140.1l140.1,140.1
		c17.4,17.9,46,18.3,63.9,0.8c0.3-0.3,0.6-0.6,0.8-0.8C981.7,255.6,985.9,240.1,982.4,225.4z"/>
            <path fill={fill} d="M265.8,905.6L126.1,765.5l139.7-140.1c17.9-17.9,17.9-46.9,0-64.7s-46.9-17.9-64.7,0l-171,172.6l0,0
		c-17.9,17.4-18.3,46-0.8,63.9c0.3,0.3,0.6,0.6,0.8,0.8l172.2,172.2c10.6,10.8,25.8,15.7,40.6,12.9c5.8-1.2,11.4-3.5,16.3-6.7
		c2.6-1.6,5-3.5,7.1-5.7l0,0c17.8-17.8,17.9-46.6,0.2-64.4C266.3,906,266.1,905.8,265.8,905.6z"/>
        </g>
    </svg>

export default CrawlSwimmer;