import React from "react";
import styled from "styled-components"
import {BsCalendar4} from "react-icons/bs";

const ScheduleCard = ({day, hours}) =>
    <Wrapper>
        <IconWrapper>
            <BsCalendar4 style={{marginRight: 10}}/>
            <p>{day}</p>
        </IconWrapper>
        {hours.map((hour, i) => (
            <StyledCard key={i}>
                <p key={i}>{hour.from} - {hour.to}</p>
            </StyledCard>
        ))}
    </Wrapper>

export default ScheduleCard;

const Wrapper = styled.div`
  padding: 10px;
`;

const StyledCard = styled.div.attrs({
    className: "radius6 font25 textCenter"
})`
  border: #0B093B solid 1px;
  padding: 10px;
  margin: 10px 0 10px 0;
`;

const IconWrapper = styled.div.attrs({
    className: "flexCenter textCenter font30"
})`
  margin-bottom: 20px;
`
